/* Header */
.bg-content-parallax {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
  background-position: left;
  background-image: url('../assets/img/desk7.jpg');
  opacity: 1;
  top: 0px;
}

header {
  min-height: 100vh;
  top: 0px;
  opacity: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  background: rgba(31, 56, 80, 0.75);
}

.navbar-brand {
  position: absolute;
}

.mainNav {
  position: absolute;
  width: 100%;
  padding: 2vh;
  text-align: center;
  top: 0;

  box-shadow: 0px 0px 0.75em #111;
  color: #fcfcfc;
}

.nav-links {
  vertical-align: middle;
  font-size: 16px;
  text-transform: uppercase;

}

.nav-links a {
  padding: 0 1em;

}

.nav-links a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.mainNav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .mainNav {
    background: whitesmoke;
    color: #5d5d5d;
  }

  .nav-links a {
    display: none;
  }

  .nav-links a:hover {
    color: black;
  }

  .mainNav .icon {
    float: right;
    display: block;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 600px) {
  .mainNav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .mainNav.responsive .nav-links {
    position: relative;
  }

  .mainNav.responsive .nav-links a:not(:last-child) {
    float: none;
    display: block;
    text-align: center;
    padding: .75em;
  }

  .mainNav.responsive .nav-links a:first-child {
    padding-top: 50px;
  }

  .mainNav.responsive .nav-links a:nth-child(2) {
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
  }
}

/* Greeting */
.greeting {
  width: 100%;
  padding: 10vh 5vh;
  text-align: center;
  font-weight: 400;
  color: whitesmoke;
}

@keyframes blinkIcon {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.console-icon {
  display: inline-block;
  margin-left: 1px;
  font-size: x-large;
  color: orange;
  animation: blinkIcon 1s step-end infinite;
}

.viewMore {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: whitesmoke;
}

.viewMore>h3 {
  color: whitesmoke;
}